<script>
import Layout from "../../layouts/main";
import TradeServiceApi from "@/service/api/trade";
import Progress from "@/service/transactionType";
import Spinner from "@/components/spinner";
import CategoriesServiceApi from "@/service/api/categories";
import TradeDetails from "@/components/trade-details";

export default {
  components: { Layout, Spinner, TradeDetails },
  data() {
    return {
      tradeData: [],
      fields: [
        { key: "id", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "created_at", sortable: false },
        { key: "status", sortable: false },
      ],
      rows: 0,
      progress: Progress.trade,
      processedData: [],
      currentPage: 1,
      perPage: 8,
      loading: false,
      categories: null,
      mainCategorySelect: null,
      primaryCatSelect: null,
      secondaryCatSelect: null,
      secondaryCate: null,
      primaryCate: null,
      filter: null,
      searchItem: null,
      selectedTrade: null,
    };
  },
  computed: {
    mainCategory() {
      if (this.categories) {
        return this.categories.map((item) => {
          return {
            label: item.name_en,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
    primaryCategoryOpt() {
      if (this.primaryCate) {
        return this.primaryCate.map((item) => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
    secondaryCategoryOpt() {
      if (this.secondaryCate) {
        return this.secondaryCate.map((item) => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.loadTradeData();
    this.loadCategories();
  },
  watch: {
    currentPage(val) {
      this.loadTradeData(val);
    },
    perPage(val) {
      this.perPage = val;
      this.loadTradeData(1);
    },
    mainCategorySelect(val) {
      this.secondaryCate = [];
      this.primaryCate = [];
      this.primaryCatSelect = null;
      this.secondaryCatSelect = null;
      this.primaryCate = this.filterPrimaryCategory(val);
    },
    primaryCatSelect(val) {
      this.secondaryCate = [];
      this.secondaryCatSelect = null;
      this.secondaryCate = this.filterSecondaryCategory(val);
    },
  },
  methods: {
    loadCategories() {
      CategoriesServiceApi.getAllCategories().then((response) => {
        this.categories = response.data;
      });
    },
    loadTradeData(page = 1) {
      this.loading = true;
      this.tradeData = [];
      this.processedData = [];
      this.rows = 0;
      let args = {
        page: page,
        paginate: this.perPage,
      };
      if (this.filter) {
        args = Object.assign(args, this.filter, { type: "trade_filter" });
      }
      TradeServiceApi.getAllTrades(args)
        .then((response) => {
          console.log(response);
          if (response.data) {
            this.tradeData = response.data.trades.data;
            this.processedData = this.processData();
            this.rows = response.data.trades.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    openTrades(trade) {
      this.$bvModal.show("view-trade-details");
      this.selectedTrade = trade;
    },
    refreshTrade() {
      this.filter = null;
      this.perPage = 8;
      this.loadTradeData();
    },
    processData() {
      const data = this.tradeData;
      let formattedData = [];
      data.map((item) => {
        const giver_size = item.trade_items.map((size) => {
          return size.giver_size;
        });
        const receiver_size = item.trade_items.map((size) => {
          return size.receiver_size;
        });
        formattedData.push({
          id: item.id,
          created_at: item.created_at,
          giver_amount: item.giver_amount,
          receiver_amount: item.receiver_amount,
          status: item.status,
          receiver_size: receiver_size.filter((item) => item),
          giver_size: giver_size.filter((item) => item),
        });
      });
      return formattedData;
    },
    progressLabel(progress_id) {
      const label = this.progress[progress_id].label;
      const status = this.progress[progress_id].status;
      const color = this.progress[progress_id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    stripProductTitle(text) {
      const length = 25;
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    filterPrimaryCategory(id = null) {
      if (id) {
        let primeData = this.categories.filter(
          (item) => item.id === parseInt(id)
        );
        if (primeData.length > 0) {
          return primeData[0].primary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filterSecondaryCategory(id = null) {
      if (id && this.primaryCate) {
        let secondData = this.primaryCate.filter(
          (item) => item.id === parseInt(id)
        );
        if (secondData.length > 0) {
          return secondData[0].secondary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filterTrade() {
      if (this.secondaryCatSelect) {
        this.filter = { secondary_id: this.secondaryCatSelect };
        this.loadTradeData();
      } else if (this.primaryCatSelect) {
        this.filter = { primary_id: this.primaryCatSelect };
        this.loadTradeData();
      } else if (this.mainCategorySelect) {
        this.filter = { category_id: this.mainCategorySelect };
        this.loadTradeData();
      } else {
        this.filter = null;
        console.log("Please select a category!");
      }
    },
    searchByProductName() {
      if (this.searchItem.length) {
        this.filter = { name: this.searchItem };
        this.loadTradeData();
      } else {
        this.filter = null;
        console.log("Please select enter product name!");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-2 ">
          <button
            class="btn btn-sm btn-secondary mr-1"
            style="height:34px;width:40px"
            @click="refreshTrade"
          >
            <i class="ri-refresh-line"></i>
          </button>
          <div class="filter-trade mr-1" style="width:64px">
            <FormulateInput
              :options="{
                8: '8',
                16: '16',
                50: '50',
                100: '100',
              }"
              v-model="perPage"
              type="select"
              style="background-color:white"
              placeholder="Items"
            />
          </div>
          <div class="filter-trade" style="width:200px">
            <FormulateInput
              type="text"
              v-model="searchItem"
              placeholder="Product name here !"
              style="background-color:white"
            />
          </div>

          <button
            class="btn btn-primary btn-sm ml-1"
            style="height:34px"
            @click="searchByProductName"
          >
            <i class="ri-search-line"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-2 float-right">
          <div class="filter-trade">
            <FormulateInput
              :options="mainCategory"
              type="select"
              v-model="mainCategorySelect"
              style="background-color:white"
              placeholder="Category"
            />
          </div>
          <div class="filter-trade ml-1">
            <FormulateInput
              :options="primaryCategoryOpt"
              type="select"
              style="background-color:white"
              placeholder="Brand"
              v-model="primaryCatSelect"
            />
          </div>
          <div class="filter-trade ml-1" style="width:128px">
            <FormulateInput
              :options="secondaryCategoryOpt"
              v-model="secondaryCatSelect"
              type="select"
              style="background-color:white"
              placeholder="Sub Category"
            />
          </div>
          <button
            class="btn btn-primary ml-1 btn-sm"
            style="height:34px;width:38px"
            @click="filterTrade"
          >
            <i class="ri-filter-2-fill"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-5">
            <Spinner :show="loading" />
            <div
              class="d-flex flex-wrap justify-content-center"
              v-if="processedData.length"
            >
              <div
                class="trade-card"
                v-for="trade in processedData"
                :key="trade.id"
                @click="openTrades(trade)"
              >
                <div class="text-center m-2">
                  {{ moment(trade.created_at).format("MMMM Do YYYY") }}
                  <div>
                    {{ moment(trade.created_at).format("h:mm a") }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <b-carousel
                      :id="'carousel' + trade.id"
                      :interval="4000"
                      indicators
                      class="m-1"
                    >
                      <!-- Text slides with image -->
                      <b-carousel-slide
                        v-for="(giver, index) in trade.giver_size"
                        :key="index"
                        :img-src="giver.product.image"
                      >
                        <div class="trade-card-text">
                          <div>
                            {{ stripProductTitle(giver.product.name) }}
                          </div>
                          <div>
                            {{ giver.size }}
                          </div>
                        </div>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="col-2">
                    <img
                      class="img-fluid mt-4"
                      src="@/assets/images/arrow.png"
                      alt=""
                    />
                    <div style="width:100px;">
                      <div
                        style="font-size:12px;margin-top:10px;color:green;"
                        v-if="trade.giver_amount"
                      >
                        + AED {{ trade.giver_amount }}
                      </div>
                      <div
                        style="font-size:12px;margin-top:10px;color:red;"
                        v-if="trade.receiver_amount"
                      >
                        - AED {{ trade.receiver_amount }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <b-carousel
                      :id="'carousel-receiver' + trade.id"
                      :interval="4000"
                      indicators
                      class="m-1"
                    >
                      <!-- Text slides with image -->
                      <b-carousel-slide
                        v-for="(receiver, index) in trade.receiver_size"
                        :key="index"
                        :img-src="receiver.product.image"
                      >
                        <div class="trade-card-text">
                          <div>
                            {{ stripProductTitle(receiver.product.name) }}
                          </div>
                          <div>
                            {{ receiver.size }}
                          </div>
                        </div>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!processedData.length && !loading"
              style="text-align:center;font-size:24px;padding:100px;font-weight:600"
            >
              No trade
            </div>
          </div>
          <div class="row" style="margin:auto;padding:50px;">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-if="tradeData.length"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TradeDetails :selectedTrade="selectedTrade" />
  </Layout>
</template>

<style>
.trade-card {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 285px;
  margin: 5px;
  width: 420px;
  border-radius: 30px;
  z-index: 0;
}

.trade-card-text :first-child {
  font-weight: 100;
  font-size: 14px;
}
.trade-card ol.carousel-indicators {
  bottom: 0px;
}
.trade-card .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
}
.trade-card .carousel-caption {
  bottom: -95px;
  position: absolute;
  color: #000;
}

.trade-card .carousel-inner {
  height: 230px;
}
.trade-card .carousel-item img {
  height: 100px;
}

.trade-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.filter-trade {
  width: 120px;
  display: inline-block;
}
</style>
