<script>
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    };
  },

  props: ["selectedTrade"],
  computed: {},
  mounted() {},
};
</script>

<template>
  <b-modal
    ref="viewTradeDetails"
    id="view-trade-details"
    hide-footer
    centered
    size="lg"
  >
    <div v-if="selectedTrade">
      <div class="row">
        <div class="col-12">
          <div class="row mb-4">
            <div class="col-6" style="text-align:end">
              <span class="trade-d-label">Giver Amount :</span>
              <span v-if="selectedTrade.giver_amount">
                {{ selectedTrade.giver_amount }}
              </span>
              <span v-else>
                N/A
              </span>
            </div>
            <div class="col-6">
              <span class="trade-d-label">Receiver Amount :</span>
              <span v-if="selectedTrade.receiver_amount">
                {{ selectedTrade.receiver_amount }}
              </span>
              <span v-else>
                N/A
              </span>
            </div>
          </div>

          <div class="giver">
            <div class="div-title">Giver</div>
            <div class="content">
              <div
                class="product"
                v-for="prd in selectedTrade.giver_size"
                :key="prd.id"
              >
                <img
                  class="img-fluid"
                  :src="prd.product.image"
                  alt=""
                  style="display:block;margin:auto"
                  srcset=""
                />
                <div>
                  <div
                    style="text-align:center;margin-bottom:18px;font-size:16px;font-weight:900"
                  >
                    {{ prd.product.name }}
                  </div>

                  <div class="row">
                    <div class="col-6 prd-f-label">
                      Retail Price
                    </div>
                    <div class="col-6">
                      <span v-if="prd.product.retail_price">
                        {{ prd.product.retail_price }} AED
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 prd-f-label">
                      Gender
                    </div>
                    <div class="col-6">
                      <span v-if="prd.product.gender">
                        {{ prd.product.gender }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 prd-f-label">
                      Lowest Ask
                    </div>
                    <div class="col-6">
                      <span v-if="prd.product.lowest_ask">
                        {{ prd.product.lowest_ask }} AED
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 prd-f-label">
                      Highest Bid
                    </div>
                    <div class="col-6">
                      <span v-if="prd.product.highest_bid">
                        {{ prd.product.highest_bid }} AED
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="receiver">
            <div class="div-title">Receiver</div>
            <div class="content">
              <div v-for="prd in selectedTrade.receiver_size" :key="prd.id">
                <div class="product">
                  <img
                    class="img-fluid"
                    :src="prd.product.image"
                    alt=""
                    srcset=""
                    style="display:block;margin:auto"
                  />
                  <div>
                    <div
                      style="text-align:center;margin-bottom:18px;font-size:16px;font-weight:900"
                    >
                      {{ prd.product.name }}
                    </div>

                    <div class="row">
                      <div class="col-6 prd-f-label">
                        Retail Price
                      </div>
                      <div class="col-6">
                        <span v-if="prd.product.retail_price">
                          {{ prd.product.retail_price }} AED
                        </span>
                        <span v-else>
                          N/A
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 prd-f-label">
                        Gender
                      </div>
                      <div class="col-6">
                        <span v-if="prd.product.gender">
                          {{ prd.product.gender }}
                        </span>
                        <span v-else>
                          N/A
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 prd-f-label">
                        Lowest Ask
                      </div>
                      <div class="col-6">
                        <span v-if="prd.product.lowest_ask">
                          {{ prd.product.lowest_ask }} AED
                        </span>
                        <span v-else>
                          N/A
                        </span>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-6 prd-f-label">
                        Highest Bid
                      </div>
                      <div class="col-6">
                        <span v-if="prd.product.highest_bid">
                          {{ prd.product.highest_bid }} AED
                        </span>
                        <span v-else>
                          N/A
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.giver {
  border: #ded5d5 1px solid;
  border-radius: 30px;
  background-color: #f1f1f1;
}
.receiver {
  margin-top: 30px;
  border: #ded5d5 1px solid;
  border-radius: 30px;
  background-color: #f1f1f1;
  max-width: 850px;
}
.div-title {
  position: absolute;
  padding: 1px 10px;
  text-align: center;
  display: inline-block;
  background-color: #fff;
  margin-top: -8px;
  margin-left: 28px;
  border-radius: 30px;
}
.content {
  padding: 20px;
}
.product {
  width: 280px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 10px;
  border-radius: 18px;
  padding: 10px;
  background-color: #fff;
  display: inline-block;
}

.product img {
  width: 180px;
  height: 140px;
}
.prd-f-label {
  color: gray;
  text-align: end;
  font-weight: 900;
}
.trade-d-label {
  color: gray;
  font-weight: bold;
  margin-right: 10px;
}
</style>
